import React, { useState, useEffect, useRef } from 'react'
import logo from './logo.svg'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"
import { publish } from "./utils/pubsub"
import CloudformationOutputs from './CloudformationOutputs.json'
import Header from './components/Header'
import Footer from './components/Footer'
import Dashboard from './components/Dashboard'
import EditAsset from './components/EditAsset'
import ListAssets from './components/ListAssets'
import LoginRedirect from './components/LoginRedirect'
import ServiceRecord from './components/ServiceRecord'
import brandstylesjson from './utils/brandstyles.json'

type ObjectAny = {
  [key: string]: any
}

type Organisations = null | ObjectAny

function App() {

  const [loggedIn, setLoggedIn] = useState<boolean | string>(false)
  const [idToken, setIdToken] = useState<string | null>(null)
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [socket, setSocket] = useState<WebSocket | null>(null)
  const [connectionState, setConnectionState] = useState('disconnected')
  const [connectionId, setConnectionId] = useState<string | null>(null)
  const [userData, setUserData] = useState<ObjectAny | null>(null)
  const [organisations, setOrganisations] = useState<Organisations>(null)
  const [orgSettings, setOrgSettings] = useState([])
  const [currentOrganisation, setCurrentOrganisation] = useState<string | null>(null)
  const loginUrl = `https://${CloudformationOutputs.UserPoolDomain}.auth.${CloudformationOutputs.AWSRegion}.amazoncognito.com/login?client_id=${CloudformationOutputs.UserPoolClientId}&response_type=token&scope=email+openid+phone+profile&redirect_uri=${window.location.origin}/dashboard/`
  const logoutUrl = `https://${CloudformationOutputs.UserPoolDomain}.auth.${CloudformationOutputs.AWSRegion}.amazoncognito.com/logout?client_id=${CloudformationOutputs.UserPoolClientId}&logout_uri=${window.location.origin}/`
  const WebSocketURI = CloudformationOutputs.WebSocketURI
  const [tableData, setTableData] = useState<ObjectAny | null>(null)
  const brandstyles: ObjectAny = brandstylesjson['assetmanagement']
  const tableDataRef = useRef<any>(null)


  useEffect(() => {
    if (idToken && accessToken && socket) {
      if (!tableData || !tableData['Assets']) {
        //console.log('fetching assets')
        const payload = JSON.stringify({
          action: "fetchSingleTableData",
          idToken: idToken,
          accessToken: accessToken,
          tableName: "Assets"
        })
        sendMessageToWebsocket(payload)
      }
    } else {
      //console.log('not fetching assets')
    }
  })


  useEffect(() => {
    setConnectionState('tryToConnect')
  }, [])



  useEffect(() => {
    if (tableData) {
      tableDataRef.current = tableData
    }


  }, [tableData])
 

  useEffect(() => {
    switch (connectionState) {
      case 'closed':
      case 'error':
        setConnectionState('waitThenReconnect')
        setTimeout(() => {
          setConnectionState('tryToConnect')
        }, 200)
        break;
      case 'tryToConnect':
        setConnectionState('connecting')
        const newSocket = new WebSocket(WebSocketURI)
        newSocket.onopen = () => {
          setSocket(newSocket)
          setConnectionState('connected')
        }
        newSocket.onclose = () => {
          console.log("🔌  socket closed")
          setConnectionState('closed')
        }
        newSocket.onerror = () => {
          setConnectionState('error')
          console.log("🔌 socket error")
        }
        break;

    }

  }, [connectionState])



  useEffect(() => {
    // handle incoming messages
    if (socket !== null) {
      actOnMessage()
    }
  }, [socket])

  useEffect(() => {
    const selectedOrgId = window.localStorage.getItem('selectedOrgId')
    if (selectedOrgId) {
      setCurrentOrganisation(selectedOrgId)
    }
  }, [socket])



  useEffect(() => {
    checkForToken()
  }, [])


  useEffect(() => {
    if (idToken && accessToken && socket) {
      // sending tokens to websocket!
      const payloadObj: { [index: string]: string } = {
        action: "login",
        idToken: idToken,
        accessToken: accessToken
      }
      const selectedOrgId = localStorage.getItem("selectedOrgId")
      if (selectedOrgId) {
        payloadObj['selectedOrgId'] = selectedOrgId
      }
      const payload = JSON.stringify(payloadObj)
      sendMessageToWebsocket(payload)
    }
  }, [idToken, accessToken, socket, loggedIn, currentOrganisation])




  const checkForToken = () => {
    //console.log('checking for a token')
    const localIdToken = localStorage.getItem("localIdToken")
    const localAccessToken = localStorage.getItem("localAccessToken")
    const queryStringParams = window.location.hash.replace("#", "").split(/[&=]+/)

    let idToken = null
    let accessToken = null

    const indexOfIdToken = queryStringParams.indexOf('id_token')
    if (indexOfIdToken >= 0) {
      idToken = queryStringParams[indexOfIdToken + 1]
    }
    const indexOfAccessToken = queryStringParams.indexOf('access_token')
    if (indexOfAccessToken >= 0) {
      accessToken = queryStringParams[indexOfAccessToken + 1]
    }


    if (idToken && accessToken) {
      //console.log('login pending')
      setLoggedIn('pending')
      setIdToken(idToken)
      setAccessToken(accessToken)
      localStorage.setItem('localIdToken', idToken)
      localStorage.setItem('localAccessToken', accessToken)
      window.location.href = '/dashboard'

    } else if (localIdToken && localAccessToken) {
      //console.log('logged in earlier')
      setIdToken(localIdToken)
      setAccessToken(localAccessToken)
    } else {
      //console.log('not logged in')
      window.location.href = loginUrl
    }
  }

  const switchCurrentOrganisation = (organisationId: string) => {
    //console.log(`Organization id change requested to ${organisationId}`)
    localStorage.setItem("selectedOrgId", organisationId)
    setCurrentOrganisation(organisationId)
    setTableData(null)

    if (idToken && accessToken && socket) {
      const payloadObj: { [index: string]: string } = {
        action: "login",
        idToken: idToken,
        accessToken: accessToken,
        selectedOrgId: organisationId
      }
      const payload = JSON.stringify(payloadObj)
      sendMessageToWebsocket(payload)
    }

  }

  const sendMessageToWebsocket = async (payload: string) => {
    if (socket) {
      //console.log(`sending to websocket: ${JSON.parse(payload).action}`)
      socket.send(payload)
    } else {
      console.log('🚫  Can not send to web socket')
    }
  }

  const actOnMessage = () => {
    if (socket && socket.onmessage === null) {
      socket.onmessage = ({ data }: any) => {
        //console.log(`A message has been received: ${data}`)

        if (data) {
          const action = JSON.parse(data).action
          const payload = JSON.parse(data).payload
          publish(action, payload)

          switch (action) {
            case 'autherror':
              console.log('🚫  Authentication error - logging you out')
              window.location.href = logoutUrl
              break

            case 'loggedIn':
              setLoggedIn(true)
              setConnectionId(JSON.parse(data).body.connectionId)
              setUserData(JSON.parse(data).body.idTokenDecoded.payload)
              setOrganisations(JSON.parse(JSON.parse(data).body.idTokenDecoded.payload.organisationsForUser))
              setOrgSettings(JSON.parse(data).body.settings)
              break

            case 'loginFailed':
              window.location.href = loginUrl
              break

            case 'loginOKButNoOrganisation':
              window.localStorage.setItem('loggedInNoOrgId', data)
              window.location.href = '/noorg'
              break

            case 'returnSingleTableData':
              const newKey = Object.keys(payload)[0]
              const newValue = payload[newKey]
              const newTableData = { ...tableDataRef.current, [newKey]: newValue }
              setTableData(newTableData)
              break
              case 'tableUpdateItemAdded':
            case 'tableUpdateItemModified':
              console.log(action)
              const tableToUpdate = payload['tableName']
              const itemToUpdate = payload['keys'][Object.keys(payload['keys'])[0]]
              const updatedTableData = { ...tableDataRef.current }
              updatedTableData[tableToUpdate] ||= {}
              updatedTableData[tableToUpdate][itemToUpdate] = payload['newRecord']
              setTableData(updatedTableData)
              break
            case 'tableUpdateItemRemoved':
              const tableToRemoveItemFrom = payload['tableName']
              const itemToRemoveKey = payload['keys'][Object.keys(payload['keys'])[0]]
              const tableDataWithItemRemoved = { ...tableDataRef.current }
              if(tableDataWithItemRemoved[tableToRemoveItemFrom] && tableDataWithItemRemoved[tableToRemoveItemFrom][itemToRemoveKey] ) {
                delete tableDataWithItemRemoved[tableToRemoveItemFrom][itemToRemoveKey]
              }
              setTableData(tableDataWithItemRemoved)
              break
          

            case 'ok':
              console.log('super admin command ok')
              break

            case 'returnPresignedUrl':
            case 'addItemResponse':
            case 'updateItemResponse':
            case "fetchSingleItemResponse":
            case "addItemSignedUploadURL":
              
              // these are handled using pub sub
              break


            default:
              console.log(`A message has been received but the action is invalid. ${data}`)
              break
          }
        }
      }
    }
  }






  return <div className={`flex flex-col min-h-screen justify-between`}>
    <div className={`flex flex-col flex-grow`}>


      <Router>
        <Header
          loggedIn={loggedIn}
          logoutUrl={logoutUrl}
          setLoggedIn={setLoggedIn}
          setUserData={setUserData}
          userData={userData}
          currentOrganisation={currentOrganisation}
          switchCurrentOrganisation={switchCurrentOrganisation}
          brandstyles={brandstyles}
        />
        <Routes>


          <Route
            path='/*'
            element={
              <Dashboard
                loggedIn={loggedIn}
                loginUrl={loginUrl}
                orgSettings={orgSettings}
                socket={socket}
                idToken={idToken}
                setIdToken={setIdToken}
                accessToken={accessToken}
                setAccessToken={setAccessToken}
                sendMessageToWebsocket={sendMessageToWebsocket}
                setLoggedIn={setLoggedIn}
                currentOrganisation={currentOrganisation}
                tableData={tableData}
                setTableData={setTableData}
              />
            } />


          <Route
            path='/assets'
            element={
              <ListAssets
                socket={socket}
                idToken={idToken}
                accessToken={accessToken}
                sendMessageToWebsocket={sendMessageToWebsocket}
                tableData={tableData}
                setTableData={setTableData}
                organisationId={currentOrganisation}
              />
            } />



          <Route
            path='/asset'
            element={
              <EditAsset
                socket={socket}
                idToken={idToken}
                accessToken={accessToken}
                sendMessageToWebsocket={sendMessageToWebsocket}
                tableData={tableData}
                setTableData={setTableData}
                orgSettings={orgSettings}
                organisationId={currentOrganisation}
              />
            } />

          <Route
            path='/asset/:Id'
            element={
              <EditAsset
                socket={socket}
                idToken={idToken}
                accessToken={accessToken}
                sendMessageToWebsocket={sendMessageToWebsocket}
                tableData={tableData}
                setTableData={setTableData}
                orgSettings={orgSettings}
                organisationId={currentOrganisation}
              />
            } />


          <Route
            path='/servicerecord/:Id'
            element={
              <ServiceRecord
                socket={socket}
                idToken={idToken}
                accessToken={accessToken}
                sendMessageToWebsocket={sendMessageToWebsocket}
                tableData={tableData}
                setTableData={setTableData}
                orgSettings={orgSettings}
              />
            } />







        </Routes>
      </Router>
    </div>
    <Footer
      brandstyles={brandstyles}
    />
  </div>
}

export default App
