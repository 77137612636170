import React, { useState } from 'react'
import { Link } from "react-router-dom"
import Modal from './Modal'

type ObjectAny = {
  [key: string]: any
}

type Props = {
  loggedIn: Boolean | string
  logoutUrl: string
  setLoggedIn: React.Dispatch<React.SetStateAction<any>>
  setUserData: React.Dispatch<React.SetStateAction<any>>
  userData: ObjectAny | null
  currentOrganisation: string | null,
  switchCurrentOrganisation?: (organisationId: string) => void
  brandstyles: { [key: string]: any }
  logoLink?: string
}

type LoggedInProps = {
  userData: ObjectAny | null
  handleLogout: () => void
  currentOrganisation: string | null,
  switchCurrentOrganisation?: (organisationId: string) => void
  setShowModal: React.Dispatch<React.SetStateAction<any>>,
  organisations: ObjectAny | null
  buttonbgcolor: string
  brandstyles: { [key: string]: any }
}


function LoggedIn({
  userData,
  handleLogout,
  currentOrganisation,
  switchCurrentOrganisation,
  setShowModal,
  organisations,
  buttonbgcolor,
  brandstyles
}: LoggedInProps) {

  const [showMenu, setShowMenu] = useState(false)
  const [showOrgsMenu, setShowOrgsMenu] = useState(false)

  const numberOfOrganisationsForThisUser = organisations ? Object.keys(organisations).length : 0
  return <div>

    <div className={`hidden md:flex flex-col items-start justify-between gap-2 text-xs`}>
      <div className={`text-white flex flex-row items-center gap-2`}>
        <img src='/usericon.svg' className={`w-6`} alt='' />
        <p>{userData!.name}</p>
      </div>
      {currentOrganisation && organisations && organisations[currentOrganisation] && <p className={`text-white`}>{organisations[currentOrganisation]}</p>}
      <div className={`flex flex-row items-center justify-between gap-2`}>
        {switchCurrentOrganisation && numberOfOrganisationsForThisUser > 1 && <button
          onClick={() => setShowModal(true)}
          style={{ backgroundColor: `rgba(${brandstyles.secondarycolorrgb}, 1)` }}
          className={`text-xs text-white py-2 px-3 rounded hover:opacity-90 ${buttonbgcolor}`}
        >Switch organisation</button>}

        <button
          style={{ backgroundColor: `rgba(${brandstyles.secondarycolorrgb}, 1)` }}
          onClick={handleLogout}
          className={`text-xs text-white py-2 px-3 rounded hover:opacity-90`}
        >Logout </button>
      </div>
    </div>


    {!showMenu && <div
      className={`md:hidden block`}
      onClick={() => setShowMenu(true)}
    >
      <img src='/hamburger.svg' alt='Menu' className={`w-7`} />
    </div>}

    {showMenu && <div
      style={{ backgroundColor: `rgba(${brandstyles.primarycolorrgb}, 1)` }}
      className={`fixed top-0 right-0 p-2 rounded flex flex-col gap-1 text-white text-xs w-1/2 z-100`}
    >
      <div className={`flex flex-row justify-end`} onClick={() => setShowMenu(false)}>
        <img src='/close-x.svg' alt='Menu' className={`w-5`} /></div>


      <div className={`flex flex-col gap-1 p-2`}>
        <div className={`flex flex-row gap-2 items-center`}>
          <img src='/usericon.svg' className={`w-5`} alt='' />
          <p className={`font-bold`}>{userData!.name}</p>
        </div>
        {currentOrganisation && organisations && organisations[currentOrganisation] && <p className={``}>{organisations[currentOrganisation]}</p>}
      </div>

      {switchCurrentOrganisation && numberOfOrganisationsForThisUser > 1 && <>
        <div
          className={`text-left font-bold text-xs text-white p-2 rounded hover:opacity-90`}
          style={{ backgroundColor: `rgba(${brandstyles.secondarycolorrgb}, 1)` }}
        >

          <div className={`flex flex-row items-center gap-2`} onClick={() => setShowOrgsMenu(!showOrgsMenu)}>
            <p className={``}>Switch organisation</p>
            <img src='/arrow.svg' alt='' className={`h-3 ${showOrgsMenu && 'rotate-90'}`} />
          </div>


          {organisations && switchCurrentOrganisation && showOrgsMenu &&
            <div className={`pt-2 text-left`}>
              {
                Object.keys(organisations).map((id, index) => {
                  return <div
                    className={`${currentOrganisation === id && 'hidden'} underline py-1 font-normal`}
                    key={index}
                    onClick={() => {
                      switchCurrentOrganisation(id)
                      // setShowMenu(false)
                    }}>
                    {organisations[id]}
                  </div>
                })
              }
            </div>}
        </div>
      </>}



      <button
        onClick={handleLogout}
        className={`text-left font-bold text-xs text-white p-2 rounded hover:opacity-90`}
        style={{ backgroundColor: `rgba(${brandstyles.secondarycolorrgb}, 1)` }}
      >
        Logout
        </button>
    </div>}


  </div>
}

function Header({
  loggedIn,
  logoutUrl,
  setLoggedIn,
  setUserData,
  userData,
  currentOrganisation,
  switchCurrentOrganisation,
  brandstyles,
  logoLink
}: Props) {


  const handleLogout = () => {
    setLoggedIn(false)
    setUserData(null)
    localStorage.removeItem('localIdToken')
    localStorage.removeItem('localAccessToken')
    window.location.href = logoutUrl
  }


  const organisations = userData ? JSON.parse(userData.organisationsForUser) : {}
  const [showSwitchOrg, setShowModal] = useState(false)



  return <header
    className={`flex flex-col items-center ${brandstyles.primarybgcolor}`}
    style={{ background: `linear-gradient(rgba(${brandstyles.primarycolorrgb}, 0.8), rgba(${brandstyles.primarycolorrgb}, 0.8)), url(/jessica-lewis-fJXv46LT7Xk-unsplash.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}
  >
    {showSwitchOrg && switchCurrentOrganisation && <Modal
      setShowModal={setShowModal}
      showCloseButton={true}
      closeButtonValue={false}
    >
      <p className={`${brandstyles.primaryheadlinecolor} font-bold text-lg mb-3`}>Switch organisation</p>
      {
        Object.keys(organisations).map((id, index) => {
          return <div
            className={`${currentOrganisation === id && 'hidden'}
          border-none cursor-pointer px-3 py-2 rounded hover:opacity-90 mb-1 bg-gray-200 hover:bg-gray-300 text-xs`}
            key={index}
            onClick={() => {
              switchCurrentOrganisation(id)
              setShowModal(false)
            }}>
            {organisations[id]}
          </div>
        })
      }
    </Modal>}


    <div className={`max-w-3xl w-full p-4 flex flex-row items-center justify-between gap-5`}>
      <Link to={logoLink ? logoLink : '/dashboard'}>
        <img
          src={brandstyles.logo}
          alt='ChecQR Logo'
          className={`border-0`}
          style={{ width: '200px' }}
        />
      </Link>

      {loggedIn === true && userData &&
        <LoggedIn
          userData={userData}
          handleLogout={handleLogout}
          currentOrganisation={currentOrganisation}
          switchCurrentOrganisation={switchCurrentOrganisation}
          setShowModal={setShowModal}
          organisations={organisations}
          buttonbgcolor={brandstyles.buttonbgcolor}
          brandstyles={brandstyles}
        />
      }

    </div>
  </header>
}
export default Header