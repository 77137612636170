import React, { useEffect, useState } from 'react'
import { json, Link, useParams, useNavigate } from 'react-router-dom'
import { subscribe } from "../utils/pubsub"
import { MapContainer, TileLayer, Marker, Popup, CircleMarker } from 'react-leaflet'
import CloudformationOutputs from '../CloudformationOutputs.json'


type ObjectAny = {
  [key: string]: any
}

type Props = {
  tableData: ObjectAny | null,
  setTableData: React.Dispatch<React.SetStateAction<ObjectAny | null>>
  socket: WebSocket | null
  sendMessageToWebsocket: (payload: string) => void
  idToken: string | null
  accessToken: string | null
  orgSettings: any
}

function EditAsset({
  tableData,
  setTableData,
  socket,
  sendMessageToWebsocket,
  idToken,
  accessToken,
  orgSettings
}: Props) {



  let { Id } = useParams()
  const [assetsEditStatus, setAssetsEditStatus] = useState<string | null>(null)
  const [formFields, setFormFields] = useState<ObjectAny>({})
  const [uploadedFile, setUploadedFile] = useState<any>(null)
  const [userPosition, setUserPosition] = useState<any>(null)
  const [mode, setMode] = useState('showform')
  const navigate = useNavigate()


  const dummyData = [
    {
      testDate: "11/04/2023",
      satisfactory: true,
      engineerId: "GX649",
      nextTestDate: "11/05/2023"
    },
    {
      testDate: "11/05/2023",
      satisfactory: true,
      engineerId: "LB578",
      nextTestDate: "11/06/2023"
    },
    {
      testDate: "11/06/2023",
      satisfactory: true,
      engineerId: "VS849",
      nextTestDate: "11/07/2023"
    },
    {
      testDate: "11/07/2023",
      satisfactory: false,
      engineerId: "MB758",
      nextTestDate: "11/08/2023",
      notes: 'Fire extinguisher must be on a stand with a sign'
    }
  ]


  useEffect(() => {

    if (Id && tableData && !tableData['Assets'][Id]) {
      setMode('notfound')
    }
    if (Id && tableData && tableData["Assets"]) {
      setFormFields(tableData['Assets'][Id])
    }

  }, [tableData])



  return <div className={`flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>



      <div className={`w-full flex flex-row items-center gap-5`}>
        <Link
          className={`border-2 border-brandmidblue hover:border-blue-700 text-brandmidblue hover:text-blue-700 font-bold p-1 rounded hover:opacity-90-full text-sm flex justify-center items-center`}
          style={{ width: '30px', height: '30px' }}
          to={`/assets`}
        >
          ◀︎
        </Link>
        <div>
          <h2 className={`text-1xl text-brandmidblue`}>Service record for:</h2>
          <h2 className={`text-3xl md:text-4xl font-bold text-brandmidblue`}>{formFields && formFields['AssetName'] ? formFields['AssetName'] : ''}</h2>
        </div>
      </div>


      {mode === 'loading' &&
        <div className={``} onClick={() => setAssetsEditStatus(null)}>
          <div className={``}>
            <div role="status">
              <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <p>Loading....</p>
            </div>
          </div>
        </div>
      }


      {mode === 'notfound' && <p>Sorry, this asset could not be found</p>}

      {mode === 'showform' &&
        <div className={`flex flex-col gap-2 w-full`}>
          {dummyData.map((record, index) => {
            return <div className={`p-4 bg-white border border-gray-200 rounded hover:opacity-90-lg shadow  flex flex-col gap-2`}>
              <div className={`flex flex-col sm:flex-row  gap-6`}>
                <div className={`flex flex-col justify-center`}>
                  <p>{record.testDate}</p>
                </div>
                <div className={`${record.satisfactory ? 'bg-green-100' : 'bg-red-100'} px-2 py-1 rounded hover:opacity-90-md flex flex-col justify-center items-center w-32`}>
                  <p className={`${record.satisfactory ? 'text-green-800' : 'text-red-800'} text-xs font-bold`}>
                    {record.satisfactory ? '✅  Satisfactory' : '⛔️  Unsatisfactory'}
                    </p>
                </div>
                <div>
                  <p className={`text-xs`}>Next test date:</p>
                  <p>{record.nextTestDate}</p>
                </div>
                <div>
                  <p className={`text-xs`}>Engineer ID:</p>
                  <p>{record.engineerId}</p>
                </div>
              </div>
              {record.notes && <div className={`border-t border-gray-300 pt-3`}>
                  <p className={`text-xs`}>Notes: {record.notes}</p></div>}
            </div>
          })}


        </div>
      }



    </div>
  </div>
}
export default EditAsset