import React from 'react'
import { Link } from "react-router-dom"

type Props = {
  brandstyles: { [key: string]: any }
}

function Footer({
  brandstyles
}: Props) {

  return <footer
    className={`w-full flex justify-center`}
    style={{backgroundColor: `rgba(${brandstyles.primarycolorrgb},1)`}}
  >
    <div className={`max-w-3xl w-full p-5 text-white flex flex-row justify-between items-center gap-6`}>

      <div className={`text-xs`}>
        ChecQR is a <a href='https://kakapolabs.co.uk' className={`underline`}>Kakapo Labs</a> project.
      &copy; W&nbsp;O&nbsp;Lahr Ltd.
      </div>

      <div className={'flex flex-row gap-4 items-center'}>
        <a href='https://www.linkedin.com/company/93777705/admin/feed/posts/'><img className={`h-6`} src='/social-linkedin.svg' alt='LinkedIn' /></a>
        {/* <a href=''><img className={`h-6`} src='/social-twitter.svg' alt='Twitter' /></a>
        <a href=''><img className={`h-6`} src='/social-instagram.svg' alt='Instagram' /></a> */}
        <a href='https://www.youtube.com/@ChecQR'><img className={`h-6`} src='/social-youtube.svg' alt='YouTube' /></a>
      </div>

    </div>
  </footer>
}
export default Footer